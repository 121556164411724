import React from 'react';
import Meta from './../components/Meta';
import HeroSection2 from './../components/HeroSection2';
import TeamBiosSection from './../components/TeamBiosSection';
import CtaSection from './../components/CtaSection';

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection2
        title={
          <>
            I'm <span className="font-light">Patrick Tricenio</span>
          </>
        }
        subtitle="A passionate sole trader software developer from Auckland, New Zealand empowering small businesses."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        leftImage=""
        rightImage=""
      />
      <TeamBiosSection
        title="Live Projects"
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <CtaSection
        title={
          <>
            More Projects <span className="text-blue-600">Available</span>!
          </>
        }
        // subtitle="Inspiring results from day one without the pain. Get your own custom dashboard and start building amazing services."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default AboutPage;
