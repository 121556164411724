import React from 'react';
import Meta from './../components/Meta';
import HeroSection from './../components/HeroSection';
import FeaturesSection from './../components/FeaturesSection';
import ClientsSection from './../components/ClientsSection';
import TestimonialsSection from './../components/TestimonialsSection';
import CtaSection from './../components/CtaSection';

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        title="Get your business online  "
        subtitle="We'll help you maximise your business success Whether you're a startup looking for your first-ever website, needing advise on your current website, new university graduates and seasoned professionals wanting to show your portfolio our simple pricing plans keep things... well... simple  "
        strapline=""
        size="lg"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <FeaturesSection
        title="The best tech under the hood"
        subtitle="Build user friendly and modern dashboards using the latest tech. Now is the time with a best selling UI framework."
        strapline="Created with passion"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <ClientsSection
        title="You're in good company"
        subtitle=""
        strapline="Lots of happy customers"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <TestimonialsSection
        title="Customer Testimonials"
        subtitle=""
        strapline="Real Feedback"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <CtaSection
        title={
          <>
            Create an account <span className="text-blue-600">today</span>!
          </>
        }
        subtitle="Inspiring results from day one without the pain. Get your own custom dashboard and start building amazing services."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default IndexPage;
