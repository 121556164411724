import React, { useEffect } from 'react';

const AnyChatWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'contactus-jssdk';
    script.src =
      'https://api.anychat.one/widget/7c95bd4a-1c60-3f9d-8d7f-17a611a29cc8?r=' +
      encodeURIComponent(window.location);
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript);

    return () => {
      document.getElementById('contactus-jssdk').remove();
    };
  }, []);

  return null;
};

export default AnyChatWidget;
